:root {
  --color-primary: #eeeeee;
  --color-primary-variant: blue;
  --color-secondary: #aaaaaa;
  --color-secondary-variant: ;
  --color-surface: #222222;
  --color-error: #b00020;
}

div.App {
  background: #111111;
  color: var(--color-primary);
  width: 100vw;
  height: 100dvh;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

div.app {
  display: flex;
  height: 100%;
  gap: 1rem;
  padding: 1rem;
  overflow: hidden;

  @media (max-width: 640px) {
    flex-direction: column;
    gap: .25rem;
    padding: .25rem;
  }
}

button {
  border: 1px solid var(--color-secondary);
  border-radius: 5px;
  background: var(--color-primary);
}

.container {
  background: var(--color-surface);
  display: flex;
  border: 1px solid var(--color-secondary);
  border-radius: 5px;
  flex-direction: column;
  padding: 1rem;
  flex: 1;
  overflow: hidden;
}

[draggable="true"] {
  cursor: grab;
}

h2 {
  font-size: 1.125rem;
  line-height: 1.125rem;
}
