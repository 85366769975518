div.chapter-controls {
  display: flex;

  h2 {
    margin: 0;
  }

  span {
    margin-left: auto;
  }

  button {
    background: white;
    border: 1px solid grey;
    border-radius: 5px;
    cursor: pointer;
    margin-left: .25rem;
  }
}
