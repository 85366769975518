div.inventory {
  // https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
  --grid-layout-gap: .5rem;
  --grid-column-count: 2;
  --grid-item-min-width: 200px;

  // Calculated values.
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  overflow: hidden;
  header {
    h2 {
      display: flex;
      margin: 0;
    }
    span {
      margin-left: auto;

      button {
        height: 100%;
        margin-left: 0.25rem;
      }
    }
  }

  ol {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
    grid-gap: var(--grid-layout-gap);
    padding: 0;
    list-style: none;
    overflow-y: scroll;
  }

  li {
    background: var(--color-primary);
    color: var(--color-surface);
    border: 1px solid grey;
    border-radius: 5px;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    width: 100%;

    span {
      margin-right: auto;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    button {
      width: 1.5rem;
      margin-left: .25rem;
    }
  }

  .inventory-item-preview {
    background: var(--color-primary);
    color: var(--color-surface);
    border: 1px solid grey;
    border-radius: 5px;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    opacity: .5;

    span {
      margin-right: auto;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    button {
      width: 1.5rem;
      margin-left: .25rem;
    }
  }

  @media (max-width: 800px) {
    --grid-layout-gap: .125rem;
    padding: .25rem;
    ol {
      margin: .25rem 0;
    }

    li, .inventory-item-preview {
      padding: .25rem .5rem;
    }
  }
}
